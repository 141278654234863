<div
  id="v2-form-field-outer-wrapper"
  [ngClass]="{
    'is-focused': control?.focused,
    'is-invalid': control?.errorState
  }"
>
  <v2-row
    id="v2-form-field-input-wrapper"
    class="border-display"
    alignItems="center"
  >
    <v2-row id="v2-form-field-label-row" alignItems="center">
      <label
        [attr.for]="control?.id"
        [attr.aria-owns]="control?.id"
        *ngIf="labelText"
      >
        <v2-label-small-secondary-text color="medium-grey">
          {{ labelText }}
        </v2-label-small-secondary-text>
      </label>
      <div
        id="v2-form-field-border-top-mirror-source"
        #v2FormFieldBorderTopMirrorSource
      ></div>
      <v2-label-small-secondary-text
        id="v2-form-field-required-text"
        color="medium-grey"
        *ngIf="control?.required"
      >
        *Required
      </v2-label-small-secondary-text>
    </v2-row>

    <ng-content></ng-content>
  </v2-row>

  <div id="v2-form-field-left-border-box" class="border-display"></div>
  <div id="v2-form-field-right-border-box" class="border-display"></div>
  <div
    id="v2-form-field-top-border-box"
    class="border-display"
    [ngStyle]="getTopBorderBoxStyle(v2FormFieldBorderTopMirrorSource)"
  ></div>
</div>

<ng-content class="subComponent" select="[subComponent]"></ng-content>

<v2-center
  id="error-text-container"
  [class.error-visible]="control?.errorState"
  [class.validation-error]="addValidationErrorStyles"
>
  <ng-content select="v2-error-text"></ng-content>
</v2-center>
