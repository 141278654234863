<ion-app>
  <ion-router-outlet
    #routerOutletElement
    (activate)="onRouterActivate()"
  ></ion-router-outlet>

  <chat-assistant-icon></chat-assistant-icon>

  <chat-assistant-dialogue
    *ngIf="chatAssistantStore.chatModalIsOpen$ | async"
  ></chat-assistant-dialogue>
</ion-app>
