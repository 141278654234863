<v2-body-header-0-text color="black" class="header-text">
  Verify Phone Number
</v2-body-header-0-text>

<v2-body-large-text class="description-text">
  Please enter the verification code we sent to your phone number
  <v2-text color="primary" id="bolded-phone-number"
    >{{(userStore.currentUser$ | async)?.phone | v2Tel}}</v2-text
  >
</v2-body-large-text>

<form (ngSubmit)="onSubmit()">
  <v2-form-field labelText="Verification Code">
    <input
      v2Input
      [formControl]="verificationCodeInputControl"
      [attr.disabled]="isSubmitting ? '' : null"
    />
    <v2-error-text>Invalid code, please try again.</v2-error-text>
  </v2-form-field>
</form>

<v2-column id="resend-code-cont" alignItems="center" justifyContent="center">
  <v2-button
    color="transparent"
    (click)="resendCode()"
    *ngIf="!isAwaitingResend"
  >
    RESEND SMS CODE
  </v2-button>

  <ng-container *ngIf="isAwaitingResend">
    <v2-body-text color="dark-grey" id="resend-text-1"
      >Verification code sent</v2-body-text
    >
    <v2-body-small-text color="medium-grey">
      ({{resendWaitTimeSeconds - currentResendIntervalSecondsElapsed + 1}}s to
      send again)
    </v2-body-small-text>
  </ng-container>
</v2-column>

<v2-row id="footer-buttons">
  <v2-button [outline]="true" color="primary" (click)="bottomSheetRef.close()">
    CANCEL
  </v2-button>
  <v2-button color="primary" (click)="onSubmit()">VERIFY</v2-button>
</v2-row>
