<div id="content-wrapper" [class.slide-out]="slideOutCommenced">
  <div
    id="card-cont"
    [style.maxHeight]="'calc(100% - ' + footerCont.offsetHeight + 'px)'"
  >
    <v2-card [flatBottom]="true" (click)="$event.stopPropagation()">
      <ng-template #contentInsertionPoint componentInsertion></ng-template>
    </v2-card>
  </div>

  <div id="footer-cont" #footerCont (click)="$event.stopPropagation()">
    <ng-template #footerInsertionPoint componentInsertion></ng-template>
  </div>
</div>
