<v2-body-header-0-text color="black" class="header-text">
  Phone Verification
</v2-body-header-0-text>

<v2-body-large-text class="description-text">
  For security reasons a verified phone number is required to start ordering.
  You will receive a text message with a 6 digit code.
</v2-body-large-text>

<form (ngSubmit)="onSubmit()">
  <v2-form-field labelText="Phone Number">
    <input
      v2Input
      type="tel"
      placeholder="e.g : 778 772 4444"
      (input)="onPhoneInput($event)"
      numbersOnly
      [formControl]="phoneInputControl"
      [attr.disabled]="isSubmitting ? '' : null"
      autocomplete="tel"
    />
    <v2-error-text>Please provide a valid phone number.</v2-error-text>
  </v2-form-field>
</form>

<v2-row id="footer-buttons">
  <v2-button [outline]="true" color="primary" (click)="bottomSheetRef.close()">
    CANCEL
  </v2-button>
  <v2-button color="primary" (click)="onSubmit()">NEXT</v2-button>
</v2-row>
