<button
  [hidden]="(chatFeatureEnabled === false ) || (primaryColor === '') || (chatAssistantStore.chatModalIsOpen$ | async)"
  [style.--primaryColor]="primaryColor"
  class="chat-icon"
  (click)="chatAssistantStore.toggleChatModal()"
>
  <img
    src="../../../../assets/images/chat-icon.svg"
    alt="Chat Icon"
    class="icon"
  />
</button>
