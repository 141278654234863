<div id="text-cont">
  <v2-body-header-0-text color="black">
    Scan QR code to order
  </v2-body-header-0-text>
  <v2-body-large-text color="black" id="middle-text">
    {{(location2Store.currentLocation$
    |async)?.openTabGuestPresenceTargetScanExpiryMessage}}
  </v2-body-large-text>
  <v2-body-large-text color="black">
    Tap the button below to scan. You may be prompted for camera access.
  </v2-body-large-text>
</div>

<v2-row id="footer-cont">
  <v2-button color="black" [outline]="true" (click)="goBack()">
    BACK
  </v2-button>
  <v2-button color="black" (click)="goToScan()">
    <v2-icon icon="camera-access" color="white" id="camera-icon"></v2-icon>
    SCAN NOW
  </v2-button>
</v2-row>
