import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { nil } from '../helpers/nil.helper';

@Pipe({
  name: 'v2Tel'
})
export class V2TelPipe implements PipeTransform {
  transform(value: string | number | nil): string {
    return value
      ? parsePhoneNumberFromString(value.toString(), 'US')?.formatNational() ??
          ''
      : '';
  }
}
