<v2-body-header-3-text color="white" id="primary-text">
  {{ toastRef.primaryText }}
</v2-body-header-3-text>

<v2-row alignItems="center">
  <v2-label-small-text color="white">
    {{ toastRef.secondaryText }}
  </v2-label-small-text>
  <v2-row id="check-circle" justifyContent="center" alignItems="center">
    <v2-icon icon="checkmark-small" color="primary"></v2-icon>
  </v2-row>
</v2-row>
