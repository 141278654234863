<ion-toolbar>
  <ng-container *ngIf="chatAssistantStore.loading$ | async; else loadedContent">
    <div class="loading-spinner-container">
      <process-overlay></process-overlay>
    </div>
  </ng-container>

  <ng-template #loadedContent>
    <div class="chat-container">
      <div class="chat-header">
        <div class="header-image-container">
          <img
            src="../../../../assets/images/ready-colour-icon.png"
            alt="chat header icon"
            class="header-image"
          />
          <span class="chat-title">Virtual Assistant</span>
        </div>
        <span (click)="chatAssistantStore.toggleChatModal()" class="back-arrow"
          >&times;</span
        >
      </div>
      <div class="loaded-content">
        <div class="chat-messages" id="chatMessagesContainer">
          <div
            *ngFor="let message of messages"
            [ngClass]="{
              'from-user message': message.role === 'user'
            }"
          >
            <ng-container
              *ngIf="message.role === 'assistant'; else userMessage"
            >
              <div class="assistant-message-container">
                <img
                  src="../../../../assets/images/ready-colour-icon.png"
                  alt="assistant icon"
                  class="from-assistant-image"
                />
                <div class="from-assistant message">
                  <span>{{ message.text }}</span>
                </div>
              </div>
            </ng-container>

            <ng-template #userMessage>
              <div>
                <span>{{ message.text }}</span>
              </div>
            </ng-template>
          </div>

          <div
            *ngIf="chatAssistantStore.sendingMessage$ | async"
            class="typing-indicator"
          >
            <div class="bubble">
              <div class="ellipsis dot1"></div>
              <div class="ellipsis dot2"></div>
              <div class="ellipsis dot3"></div>
            </div>
          </div>
        </div>

        <div
          class="footer"
          [hidden]="
            (chatAssistantStore.sendingMessage$ | async) ||
            (chatAssistantStore.chatError$ | async)
          "
        >
          <div
            class="message-options"
            *ngIf="
              !!messages[messages.length - 1]?.nextAvailablePrompts?.length
            "
          >
            <div class="option-buttons-container">
              <button
                *ngFor="
                  let prompt of messages[messages.length - 1]
                    ?.nextAvailablePrompts
                "
                class="option-button"
                (click)="handleSelectPrompt(prompt.buttonText)"
              >
                {{ prompt.buttonText }}
              </button>
            </div>
          </div>
          <div
            class="chat-input-container"
            *ngIf="
              messages[messages.length - 1]?.nextAvailablePrompts?.length === 0
            "
          >
            <input
              type="text"
              placeholder="Message"
              class="chat-input"
              [(ngModel)]="userMessage"
              (keydown.enter)="handleInputSubmission(userMessage)"
            />
            <img
              src="../../../../assets/images/send.svg"
              alt="Send"
              class="send-icon"
              (click)="handleInputSubmission(userMessage)"
              [hidden]="!userMessage"
            />
          </div>
          <div
            class="chat-static-option"
            *ngIf="
              messages[messages.length - 1]?.nextAvailablePrompts?.length ===
                0 ||
              !!messages[messages.length - 1]?.nextAvailablePrompts?.length
            "
          >
            <span
              class="chat-static-option-text"
              (click)="chatAssistantStore.toggleChatModal()"
              >I'm good, thanks!</span
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ion-toolbar>
