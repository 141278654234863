<v2-card
  (click)="$event.stopPropagation()"
  [class.slide-out]="slideOutCommenced"
  [flatBottom]="true"
>
  <v2-row id="card-header" alignItems="center">
    <v2-button id="close-button" (click)="bottomSheetRef.close()">
      <v2-icon id="close-icon" icon="close-small" color="white"></v2-icon>
    </v2-button>

    <v2-body-large-text
      *ngIf="titleText"
      id="header-title-text"
      color="white"
      [class.visible]="showCardHeaderTitle"
    >
      {{ titleText }}
    </v2-body-large-text>
  </v2-row>

  <div id="insertion-cont" #insertionCont>
    <div id="title-desc-cont" *ngIf="titleText || descriptionText">
      <v2-body-header-0-text
        *ngIf="titleText"
        id="insertion-title-text"
        color="white"
        #insertionTitle
      >
        {{ titleText }}
      </v2-body-header-0-text>
      <v2-body-text
        *ngIf="descriptionText"
        id="description-text"
        color="white"
        [class.with-title]="titleText"
      >
        {{ descriptionText }}
      </v2-body-text>
    </div>

    <ng-template componentInsertion></ng-template>
  </div>
</v2-card>
